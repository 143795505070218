td{
    white-space: nowrap;
}
.thick-border {
    border-style: double;
}
.schedule-container {
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    padding-right: 25px;
}

.schedule-table {
    vertical-align: middle;
    text-align: center;
}

.week-selector {
    padding-bottom: 10px;
}