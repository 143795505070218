.documents-page {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.add-file{
    float: right;
    margin-bottom: 10px;
}

.header-document-page {
    padding-top: 10px;
    background-color: white;
    overflow: hidden;
}

.document-title{
    float:left;
}

.sticky-table-header-document-page {
    top: 96px;
    position: sticky;
    background-color: white;
}

.sticky-table-title-document-page {
    top: 58px;
    position: sticky;
}

.sticky-category-document-page {
    top: 126px;
    position: sticky;
    background-color: white;
}