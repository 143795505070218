.reconciliation-page {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.threeColumn {
    float: left;
    width: 33%;
    padding: 15px 0px; 
}

.firstColumn {
    border-bottom: 2px grey solid;
}

.twoColumn {
    float: left;
    width: 50%;
}

.row {
    padding: 15px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

hr {
    background-color: black
}