.login-form {
    padding: 20px;
    margin: auto;
    width: 50%;
}

.login-logo {
    height: 200px;
    padding: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-button {
    float: right;
}