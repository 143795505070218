.home-page {
    padding: 10px;
}

.home-page-container {
    display: flex;
    flex-direction: row;
}

.home-page-item {
    margin: auto;
    padding: 5px;
    font-size: 20px;
}

.home-page-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}

.home-page-footer {
    display: flex;
    flex-direction: column;
}