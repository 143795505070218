.new-order-page {
    padding: 15px;
}

.sticky-table-header {
    position: sticky;
    background-color: white;
}

.sticky-category {
    position: sticky;
    background-color: white;
}

.admin-button {
    margin-right: 10px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.sticky-footer {
    bottom: 0;
    left: 0;
    padding: 15px 20px;
    z-index: 1;
    width: 100%;
    position: fixed;
    background-color: white;
    border-top: 1px solid black;
}

.sticky-header {
    top: 61px;
    left: 0;
    padding: 15px 20px;
    z-index: 1;
    width: 100%;
    position: fixed;
    background-color: white;
    border-bottom: 1px solid black;
}
