.timeslot {
    display: flex;
    flex-direction: row;
}

.child {
    width: 25%;
    margin: 5px;
}

.childInput {
    width: 13%;
    margin: 5px;
}

.save-button {
    float: right;
}