.container {
    padding-top: 60px;
}

.container-with-footer {
    padding-bottom: 40px;
}

.header {
    top: 0;
    left: 0;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid black;
    align-items: center;
    z-index: 1;
    width: 100%;
    position: fixed;
    background-color: white;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}