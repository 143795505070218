.header-item {
    width: 60%;
}

.header-button {
    width: 20%;
}

.header-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.header-page-item {
    padding: 5px 10px;
    font-size: 20px;
    white-space: nowrap;
}

.header-logo {
    height: 38px;
    float: left;
}

.header-logout {
    float: right;
}

.header-active {
    font-weight: 700;
}

.header {
    display: flex;
    flex-direction: row;
}