.past-order-page {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.past-order-title{
    padding-top: 10px;
}

.sticky-table-header-past-orders {
    top: 96px;
    position: sticky;
    background-color: white;
}

.sticky-table-title-past-orders {
    top: 58px;
    position: sticky;
    background-color: white;
}

.sticky-category-past-orders {
    top: 126px;
    position: sticky;
    background-color: white;
}
