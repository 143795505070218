.reconciliation-summary-page {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.verify-message {
    color: red;
}

.row {
    padding: 15px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}