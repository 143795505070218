.inventory-page {
    padding: 20px;
}

.inventory-page-container {
    display: flex;
    flex-direction: column;
}

.inventory-page-item {
    margin: auto;
    padding: 5px;
    font-size: 20px;
}